<template>
    <div v-if="dataSource && dataSourceType === 'ReportQuery'"
        :key="document.id"
        class="p-relative"
    >
        <Toolbar :menu="dataSource.Data.Menu" v-on:toolbar-button-click="onToolbarClick"  />
        <DocumentPanel :document-info="dataSource.Data.Object" />

        <v-tabs
            v-model="tab"
            background-color="transparent"
            slider-size="3"
            class="tab-selector-panel"
            mobile-breakpoint="0"
        >
            <v-tabs-slider color="#E43958"></v-tabs-slider>

            <v-tab 
                v-for="item in pages"
                :key="document.id + item.FormId"
                @click="onTabClicked(item.FormId)"
            >
                <template v-if="item.Count < 0">
                    {{ item.Name }}
                </template> 
                <template v-else>
                    {{ item.Name }}
                    <span class="count" id="attachCounter">{{item.Count}}</span>
                </template>   
            </v-tab>

        </v-tabs>

        <v-tabs-items
            v-model="tab"
            class="tab-content-panel"
        >
            <v-tab-item 
                v-for="item in pages"
                :key="document.id + item.FormId"
            >
                <template v-if="item.FormId == document.id">
                    <v-form class="wrapperForm" ref="form" lazy-validation>
                        <v-row dense>

                            <!--Обертка для секции-->
                            <v-col cols="12" sm="12" md="6" class="section-wrapper">
                                
                                <!--Секция Реквизиты документа-->
                                <v-card flat>
                                    <v-card-text>
                                        <div class="form-box-title">{{$t("Реквизиты_запроса")}}</div>

                                        <!--поле Номер проекта (всегда просмотр)-->
                                        <v-row no-gutters v-if="!document.is_new_record">
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Номер_запроса")}}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div class="onlyReadData">{{ document.Card.DraftNumber }}</div>
                                            </v-col>
                                        </v-row>
                                        
                                        <!--поле Шаблон отчета-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{ $t("Шаблон") }}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div v-if="!document.is_new_record" class="onlyReadData">
                                                    {{ localized(document.Card.ReportQueryTemplateName) }}
                                                </div>
                                                <v-select
                                                    v-else
                                                    :value="document.Card.ReportQueryTemplateId"
                                                    @input="update({ property: 'Data.Object.Document.Card.ReportQueryTemplateId', value: $event})"
                                                    :items="reportTemplatesItems"
                                                    :item-text="item => item.name"
                                                    item-value="id"
                                                    hide-details
                                                    required
                                                    outlined
                                                    dense
                                                    :rules="defaultGuidRule"
                                                    append-icon="fas fa-chevron-down"
                                                    :menu-props="{ bottom: true, offsetY: true }"
                                                >
                                                    <template v-slot:prepend-item>
                                                        <v-list-item>
                                                            <v-list-item-content>
                                                                <v-text-field 
                                                                    :placeholder="$t('Поиск')"
                                                                    class="mb-2 autocomplete-search"
                                                                    hide-details
                                                                    dense
                                                                    clearable
                                                                    autofocus
                                                                    v-model="templateSearch"
                                                                >
                                                                </v-text-field>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </template>
                                                </v-select>
                                            </v-col>
                                        </v-row>

                                    </v-card-text>
                                </v-card>

                                <!--Секция Исполнитель-->
                                <v-card flat v-if="document.Card.ExecuterName">
                                    <v-card-text>
                                        <div class="form-box-title">{{ $t("Ответственный") }}</div>
                                        
                                        <!--поле Ответственный-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Ответственный")}}</label>
                                            </v-col>
                                            <v-col  cols="12" sm="12" md="8">
                                                <div class="icon-click-row-group icrg-align-center">                                                    
                                                    <div class="onlyReadData">
                                                        <v-workplace-chip v-if="document.Card.ExecuterName" :id="document.Card.ExecuterWorkplaceId" :name="document.Card.ExecuterName" />
                                                    </div>
                                                </div>
                                            </v-col>
                                        </v-row>
                                        
                                        <!--поле Организация ответственного-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Организация")}}</label>
                                            </v-col>
                                            <v-col  cols="12" sm="12" md="8">
                                                <div class="onlyReadData">
                                                    <v-enterprise-chip
                                                        v-if="document.Card.ExecuterEnterpriseName"
                                                        :name="localized(document.Card.ExecuterEnterpriseName)"
                                                    />
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>

                                <!--Секция Контроль-->
                                <v-card flat v-if="isRegistered">
                                    <v-card-text>
                                        <div class="form-box-title">{{ $t("Контроль") }}</div>
                                        
                                        <!--Поле Контрольный срок-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Контрольный_срок")}}</label>
                                            </v-col>

                                            <v-col cols="12" sm="12" md="8">
                                                <div class="onlyReadData">
                                                    {{ formatDate(ControlDate) }}
                                                </div>
                                            </v-col>

                                        </v-row>

                                        <!--Поле Фактический срок-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Фактический_срок")}}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div class="onlyReadData">
                                                    {{ document.Card.ExecDate ? formatDate(document.Card.ExecDate) : $t("Не_указано") }}
                                                </div>
                                            </v-col>
                                        </v-row>

                                    </v-card-text>
                                </v-card>
                            </v-col>

                            <v-col cols="12" sm="12" md="6" class="section-wrapper">

                                <!--Секция Кем создано-->
                                <v-card flat v-if="isViewMode">
                                    <v-card-text>
                                        <div class="form-box-title">{{ $t("Кем_создано") }}</div>
                                        
                                        <!--поле Сотрудник-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Сотрудник")}}</label>
                                            </v-col>
                                            <v-col  cols="12" sm="12" md="8">
                                                <div class="onlyReadData">
                                                    <v-employee-chip :id="document.AuthorId" :name="document.AuthorName" />
                                                </div>
                                            </v-col>
                                        </v-row>

                                        <!--поле Организация автора-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Организация")}}</label>
                                            </v-col>
                                            <v-col  cols="12" sm="12" md="8">
                                                <div class="onlyReadData">
                                                    <v-enterprise-chip
                                                        v-if="localized(document.EnterpriseName)"
                                                        :name="localized(document.EnterpriseName)"
                                                    />
                                                </div>
                                            </v-col>
                                        </v-row>

                                        <!--поле Дата создания-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Дата_создания")}}</label>
                                            </v-col>
                                            <v-col  cols="12" sm="12" md="8">
                                                <div class="onlyReadData">{{ formatDate(document.CreateDate) }}</div>
                                            </v-col>
                                        </v-row>

                                        

                                    </v-card-text>
                                </v-card>

                            </v-col>

                            <v-col cols="12" sm="12" md="12" class="section-wrapper">

                                <!--Секция Краткое содержание-->
                                <v-card flat>
                                    <v-card-text>
                                        <div class="form-box-title">{{$t("Краткое_содержание")}}</div>

                                        <!--Поле Краткое содержание-->
                                        <v-row class="full-width-row" no-gutters>
                                            <v-col cols="12" sm="12" md="2">
                                                <label class="f-label">{{$t("Краткое_содержание")}}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="10">
                                                <div v-if="isViewMode" class="onlyReadData">
                                                    {{ document.Theme }}
                                                </div>
                                                <v-textarea v-else
                                                    :value="document.Theme"
                                                    @input="update({ property: 'Data.Object.Document.Theme', value: $event })"
                                                    rows="3"
                                                    no-resize
                                                    hide-details
                                                    required
                                                    outlined
                                                    dense
                                                    :rules="requiredRule"
                                                >
                                                </v-textarea>
                                            </v-col>
                                        </v-row>

                                    </v-card-text>
                                </v-card>

                            </v-col>

                        </v-row>
                    </v-form>
                </template>

                <template v-else-if="!!item">
                    <component
                        :is="$mapComponent(item.FormId)" 
                        :ref="item.FormId" 
                        :id="document.id"
                    />
                </template>

            </v-tab-item>

        </v-tabs-items>

        <SendReportOnExecutionDlg v-if="isSendReportOnExecutionVisible" />

    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import i18n from '@/i18n'
    import Toolbar from '@/components/documents/Toolbar.vue';
    import DocumentPanel from '@/components/documents/DocumentPanel.vue';
    import _ from 'lodash';
    import sys from '@/services/system';
    import { httpAPI } from '@/api/httpAPI';
    export default {
        name: "ReportQueryCard",
        components: { 
            Toolbar,
            DocumentPanel,
            SendReportOnExecutionDlg: () => import('@/components/reportsgeneration/dialogs/SendReportOnExecutionDlg'),
        },
        data() {
            return {
                tab: null,
                reportTemplates: null,
                templateSearch: ""
            }
        },
        asyncComputed:{
            reportTemplatesItems: {
                async get () {
                    if (this.document){
                        if (!this.reportTemplates){
                            let filter = {EnterpriseId: this.document.EnterpriseId};
                            let response = await httpAPI({
                                url: `api/report/reportquerytemplatelist?filter=${JSON.stringify(filter)}`,
                                method: 'GET',
                            });
                            this.reportTemplates = response?.data?.Payload?.data ?? [];
                        }
                        if (this.templateSearch)
                            return this.reportTemplates.filter(i => sys.checkSearch(i.name, this.templateSearch) || i.id == this.document?.Card?.ReportQueryTemplateId);
                        else
                            return this.reportTemplates;
                    }
                    else 
                        return [];
                    
                },
                default: [],
                watch: ['templateSearch']
            },

        },
        computed: {
            ...mapGetters('reportsgeneration/dialogs/sendreportonexecution', {isSendReportOnExecutionVisible: 'isVisible'}),
            ...mapGetters('global/actionsource', { controlInfo: 'getControlInfo', dataSource: 'getDataSource', dataSourceType: 'getDataSourceType' }),
            ...mapGetters({ 
                requiredRule: 'getRequiredRule',
                multipleRule: 'getMultipleRule',
                integerRule: 'getIntegerRule',
                integerNotNull: 'getIntegerNotNullRule',
                defaultGuidRule: 'getDefaultGuidRule'
            }),
            isRegistered() {
                return this.document.RegState == 1 || this.document.RegState == 4;
            },
            isViewMode() {
                return this.dataSource?.Data?.FormId === "18010101";
            },
            document() {
                return this.dataSource?.Data?.Object?.Document;
            },
            pages() {
                let pages = 
                [
                    { Count: -1, FormId: this.document?.id, Name: i18n.t("Карточка") }
                ];

                pages = pages.concat(this.dataSource?.Data?.Object?.Pages ?? []);

                return pages;
            },
            selectExecuterTooltip(){
                return i18n.t("Выбрать_исполнителя");
            },
            ControlDate: {
                get: function() {
                    if (this.document.Card.ControlDate)
                        return this.$moment(this.document.Card.ControlDate).format('YYYY-MM-DD');
                    
                    return this.document.Card.ControlDate;
                },
                set: function(newValue) {
                    this.updateSource({ property: 'Data.Object.Document.Card.ControlDate', value: `/Date(${Date.parse(newValue)})/` });
                }
            },
        },
        filters: {
        formattedDate: function (value) {
                var formatedDate = sys.dateFormat(value, 'DD.MM.YYYY');
                return formatedDate ?? "";
            }
        },
        methods: {
            ...mapActions('global/actionsource', { updateSource: 'updateDataSource' }),
            onTabClicked (formId) {
                if (formId === this.document?.id) return;

                let component = this.$refs[formId]?.[0];

                if (component)
                    if (typeof component.update === 'function')
                        component.update();
            },
            async onToolbarClick (event, button) {
                let actions = Reflect.ownKeys(this.$store._actions).filter(i => i.includes('global/actionsource/')).map(i => {return i.split('/')[2]})

                if (actions.includes(button.Action)) {
                    if(button.Action == 'Save' || button.Action == 'SaveAndClose'){
                        let validate_result = this.$refs.form[0].validate();
                        if(validate_result){
                            await this.$store.dispatch(`global/actionsource/${button.Action}`, {event, button});        
                        }else{
                            this.$notify.alert(i18n.t("Не_заполнены_обязательные_поля"));
                        }
                    }
                    else
                    {
                        await this.$store.dispatch(`global/actionsource/${button.Action}`, {event, button});
                    }
                }
                else
                    this.$notify.alert(`Действие_${button.Action}_не_реализовано.`);
            },
            formatDate (source) {
                return this.$moment(source).format('DD.MM.YYYY');
            },
            localized(val) {
                let currentLocale = 'ru-RU';
                let otherLocale = 'kk-KZ';
                if (this.$i18n.locale == 'kz'){
                    currentLocale = 'kk-KZ';
                    otherLocale = 'ru-RU';  
                }

                if (val == null)
                    return val;
                if (typeof val === 'string') 
                    return val;            
                else if (val[currentLocale] !== undefined)            
                    return val[currentLocale];
                else if (val[otherLocale] !== undefined)
                    return val[otherLocale];
                return null;
            },
            update: _.debounce(function (object) {
                this.updateSource(object);
            }, 250)
        },
        async created() {    
            (async() => {
                while(typeof this.$refs.form === 'undefined')
                    await new Promise(resolve => setTimeout(resolve, 100));

                this.$refs?.form?.[0]?.validate();
            })();
        },
        updated() {
            if (this.dataSource !== null) {
                this.$refs?.form?.[0]?.validate();
            }  
        },
        async mounted() {        
            this.$eventBus.$on('set-active-tab', async ({ FormId }) => {
                let page = this.pages.find(x => x.FormId === FormId);

                if (page) {
                    let targetIndex = this.pages.indexOf(page);

                    if (this.tab != targetIndex)
                        this.tab = targetIndex;
                    else
                        this.$eventBus.$emit('update-active-tab');
                }
            });
            
        },
        beforeDestroy() {
            this.$eventBus.$off('set-active-tab');
        }
    }
</script>